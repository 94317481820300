<template>
  <v-container fluid>
    <v-toolbar flat color="white">
      <v-toolbar-title class="green--text">{{ $t( "scanRecord.title" ) }}</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <span class="headline"></span>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        :label="$t('scanRecord.table_search')"
        single-line
        hide-details
      ></v-text-field>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="scanRecords"
      :search="search"
      sort-by="recordTime"
      :sort-desc="true"
      class="elevation-1"
    >
    </v-data-table>   
  </v-container>
</template>

<style scoped>
  table.v-table thead tr th {
    font-size: 28px;
  }
  table.v-table tbody tr td {
    font-size: 20px;
  }
</style>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'  
import { CallDialog, AppSyncSubscriber } from '@/utils'
import store from '@/store'


export default {
  data: () => ({
    search: '',
    dialog: false
  }),
  computed: {
    headers () {
      return [
        { text: this.$t('scanRecord.table_terminalKey'), value: 'terminalKey', align: 'left', sortable: false},
        { text: this.$t('scanRecord.table_userName'), value: 'userName', align: 'left', sortable: false},
        { text: this.$t('scanRecord.table_reservationCode'), value: 'reservationCode', align: 'left', sortable: false},
        { text: this.$t('scanRecord.table_temperature'), value: 'temperature', align: 'left', sortable: false},
        { text: this.$t('scanRecord.table_recordTime'), value: 'recordTime', align: 'left', sortable: true},
        { text: this.$t('scanRecord.mask'), value: 'mask', align: 'left', sortable: false}
      ]
    },    
    ...mapState({
      scanRecords: state => state.scanner.scanRecords
    }),
  },
  async beforeRouteEnter (to, from, next) {
    AppSyncSubscriber.connect('scanner', 'onCreateScanRecord', null, 'refreshScanRecords')

    const error = await store.dispatch('scanner/loadScanRecords').catch(error => {      
      return error
    })

    if (error) {
      CallDialog(error)
    } else {
      next()
    }
  },
  methods: {
    ...mapActions({
      loadScanRecords: 'scanner/loadScanRecords'
    })
  }
}
</script>